import * as React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

export default function FifthPage() {
  let amounts = [1, 5, 10, 50, 100, 500, 1000]

  function getCheckoutPage(amount) {
    window.location.href = `https://www.dataalp.com/checkout${amount}`
  }

  return (
    <Layout>
      <Seo title="Support Us" />
      <Navbar />

      <section id="hero" style={{ paddingTop: "60px" }}>
        <div className="container">
          <div className="row pt-5 pb-5 justify-content-between align-items-center">
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-1">
              <StaticImage
                src="../images/Team work-bro.svg"
                alt=""
                placeholder="none"
                quality="100"
                width={450}
              />
            </div>
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-1">
              <div data-aos="zoom-out">
                <h1 className="fs-2" style={{ color: "#FFFFFF" }}>
                  Support DataAlp With Tips
                </h1>
                <h2
                  className="fs-5"
                  style={{
                    textAlign: "justify",
                    lineHeight: "1.3",
                    color: "#FFFFFF",
                  }}
                >
                  Your data story is on its way, meanwhile help DataAlp grow to
                  provide even better data stories and be a part in achieving
                  data driven optimization for not only yourself but several
                  other small businesses and entities. Next in line we have the
                  time series analysis, image classification, customizable
                  neural nets, provisions to import data directly from
                  databases, even better AI & subsequent stories and so much
                  more.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-lg-0 order-2 order-lg-2 d-flex justify-content-center">
              <div>
                <h3 className="purple-heading">Tip DataAlp</h3>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center py-5">
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-2 d-flex justify-content-center">
              <div className="d-flex justify-content-between w-75 stripe-buttons">
                {amounts.map(amount => (
                  <a>
                    <button
                      onClick={() => getCheckoutPage(amount)}
                      className="btn btn-success"
                      style={{
                        backgroundColor: "#1acc8d",
                        borderColor: "#ffffff",
                      }}
                    >
                      ${amount}
                    </button>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-2">
              <div className="py-5 text-center">
                <StaticImage
                  src="../images/powered-by-stripe2.png"
                  alt=""
                  placeholder="none"
                  quality="100"
                  width={200}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}
